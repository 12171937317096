import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";

import { useLanguage } from "../components/LanguageSelect";

import styles from "./news.module.scss";

export const query = graphql`
  query($language: String!) {
    main: allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "text" }
          slug: { eq: "news" }
          language: { eq: $language }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            language
          }
          frontmatter {
            title
            date
            header {
              title
              subtitle
              description
            }
            tabs {
              merchant
              user
            }
            sections {
              section {
                id
                title
                abovetitle
                subtitle
                description
              }
            }
          }
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { fields: { category: { eq: "posts" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
          }
          fields {
            slug
            language
          }
        }
      }
    }
  }
`;

const BlogPage = (props) => {
  const language = useLanguage();
  const data = props.data.main;
  const posts = props.data.posts.edges.filter(
    (node) => node.node.fields.language === language
  );
  const pageContent = data.edges[0].node.frontmatter;
  const { header } = pageContent;

  const heading = (
    <Heading>
      <h1>{header.title}</h1>
      <h3>{header.subtitle}</h3>
      <h3>{header.description}</h3>
    </Heading>
  );

  return (
    <Layout heading={heading}>
      <Head title={header.title} />
      <ol className={styles.posts}>
        {posts.map((edge, index) => {
          return (
            <li className={styles.post} key={index}>
              <Link to={`${language}/news/${edge.node.fields.slug}`}>
                <h2>{edge.node.frontmatter.title}</h2>
                <p>{edge.node.frontmatter.date}</p>
              </Link>
            </li>
          );
        })}
      </ol>
    </Layout>
  );
};

export default React.memo(BlogPage);
